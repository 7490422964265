// ... other imports
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFoodFlyData } from '../api/PupilApi';
import { toast } from "react-hot-toast";

const AdminDashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState(null);
  const [showModalContent, setShowModalContent] = useState(false);
  const [userFilter, setUserFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    getFoodFlyData()
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        const message = error?.message || "Sorry! Something went wrong. Please try again!";
        toast.error(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filteredData = data?.content.filter(user => {
    const matchesUserFilter = userFilter === '' || user.name.toLowerCase().includes(userFilter.toLowerCase());
    const matchesEmailFilter = emailFilter === '' || user.pupilEmail.toLowerCase().includes(emailFilter.toLowerCase());
    const matchesStatusFilter = statusFilter === '' || user.orderList?.some(order => order.orderStatus.toLowerCase() === statusFilter.toLowerCase());

    return matchesUserFilter && matchesEmailFilter && matchesStatusFilter;
  });

  const openModal = (orders) => {
    setSelectedOrders(orders);
    setModalOpen(true);
    setShowModalContent(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedOrders(null);
    setShowModalContent(false);
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const handleNavigateShopOrder = () => {
    navigate("/shopdashboard");
  };

  const downloadCSV = () => {
    if (!data || !data.content) {
      console.error('No data available for CSV export');
      return;
    }

    const header = ['ID', 'Name', 'Email', 'Mobile', 'Order ID', 'Total Cost', 'Shipping Charge', 'Order Status', 'Payment Method'];
    const rows = data.content.flatMap(user =>
      user.orderList ? user.orderList.map(order => [
        user.id,
        user.name,
        user.pupilEmail,
        user.pupilMobile,
        order.order_id,
        order.totalCost,
        order.shippingCharge,
        order.orderStatus,
        order.paymentMethod,
      ]) : []
    );

    const csvContent = [header, ...rows].map(e => e.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'admin_dashboard_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) return <p className="text-center">Loading...</p>;
  if (error) return <p className="text-center text-red-500">Error loading data</p>;

  return (
    <div className="container mx-auto p-4">
      <button
        onClick={handleNavigateBack}
        className="mb-4 bg-gray-500 text-white px-4 py-2 rounded-lg mr-4"
      >
        Back
      </button>
      <button
        onClick={handleNavigateShopOrder}
        className="mb-4 bg-blue-500 text-white px-4 py-2 rounded-lg"
      >
        Goto Shop Orders
      </button>
      <button
        onClick={downloadCSV}
        className="mb-4 bg-green-500 text-white px-4 py-2 rounded-lg ml-4"
      >
        Export to CSV
      </button>
      
      {/* Filter by User Name */}
      <div className="mb-4">
        <label className="mr-2">Filter by User:</label>
        <input
          type="text"
          value={userFilter}
          onChange={(e) => setUserFilter(e.target.value)}
          className="p-2 border border-gray-300 rounded-lg"
          placeholder="Search by name"
        />
      </div>
      
      {/* New Filter by Email */}
      <div className="mb-4">
        <label className="mr-2">Filter by Email:</label>
        <input
          type="text"
          value={emailFilter}
          onChange={(e) => setEmailFilter(e.target.value)}
          className="p-2 border border-gray-300 rounded-lg"
          placeholder="Search by email"
        />
      </div>
      
      {/* Filter by Status */}
      <div className="mb-4">
        <label className="mr-2">Filter by Status:</label>
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="p-2 border border-gray-300 rounded-lg"
        >
          <option value="">All Status</option>
          <option value="Pending">Pending</option>
          <option value="Preparing">Preparing</option>
          <option value="On the way">On the way</option>
          <option value="Delivered">Delivered</option>
          <option value="Cancelled">Cancelled</option>
        </select>
      </div>
      
      <h1 className="text-2xl font-bold mb-4">Admin Dashboard</h1>
      {filteredData && filteredData.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 table-auto">
            <thead>
              <tr className="w-full bg-gray-100 text-left text-gray-700 border-b border-gray-200">
                <th className="p-3 text-sm md:text-base">ID</th>
                <th className="p-3 text-sm md:text-base">Name</th>
                <th className="p-3 text-sm md:text-base">Email</th>
                <th className="p-3 text-sm md:text-base">Mobile</th>
                <th className="p-3 text-sm md:text-base">Orders</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((user) => (
                <tr key={user.id} className="border-b border-gray-200">
                  <td className="p-3 text-sm md:text-base">{user.id}</td>
                  <td className="p-3 text-sm md:text-base">{user.name}</td>
                  <td className="p-3 text-sm md:text-base">{user.pupilEmail}</td>
                  <td className="p-3 text-sm md:text-base">{user.pupilMobile}</td>
                  <td className="p-3 text-sm md:text-base">
                    {user.orderList && user.orderList.length > 0 ? (
                      <button
                        onClick={() => openModal(user.orderList)}
                        className="text-blue-500 underline"
                      >
                        View Orders
                      </button>
                    ) : (
                      <p>No orders available</p>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center">No data available</p>
      )}

      {modalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-3xl relative">
            {showModalContent ? (
              <div className="space-y-4">
                <h2 className="text-xl font-bold mb-4">Order Details</h2>
                {selectedOrders && selectedOrders.length > 0 && (
                  <div className="overflow-y-auto max-h-[60vh]">
                    <ul className="space-y-4">
                      {selectedOrders.map((order) => (
                        <li key={order.order_id} className="p-4 border border-gray-200 rounded-lg">
                          <p><strong>Order ID:</strong> {order.order_id}</p>
                          <p><strong>Total Cost:</strong> {order.totalCost}</p>
                          <p><strong>Shipping Charge:</strong> {order.shippingCharge}</p>
                          <p><strong>Order Status:</strong> {order.orderStatus}</p>
                          <p><strong>Shop Name:</strong> {order.shopName}</p>
                          <p><strong>Payment Method:</strong> {order.paymentMethod}</p>
                          <p><strong>Ordered Person Name:</strong> {order.orderedPersonName || 'N/A'}</p>
                          <p><strong>Ordered Person Mobile:</strong> {order.orderedPersonMobile || 'N/A'}</p>
                          <p><strong>Ordered User Name:</strong> {order.orderedUserName || 'N/A'}</p>

                          {/* Food Details */}
                          <h4 className="font-semibold mt-2">Food Details:</h4>
                          <div className="flex items-center mt-2">
                            {order.image && (
                              <img src={order.image} alt={order.name} width="100" className="mr-2" />
                            )}
                            <div>
                              <p><strong>Name:</strong> {order.name || 'N/A'}</p>
                              <p><strong>Category:</strong> {order.category || 'N/A'}</p>
                              <p><strong>Price:</strong> {order.price || 'N/A'}</p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                <button
                  onClick={closeModal}
                  className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg"
                >
                  Close
                </button>
              </div>
            ) : (
              <div>
                <p className="text-center">Please select an order to view details</p>
                <button
                  onClick={() => setShowModalContent(true)}
                  className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg"
                >
                  View Orders
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
