import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from "react-router-dom"; // Added useNavigate
import { data } from '../data/data';
import Navbar from './Navbar';
import Footer from './Footer';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { PaystackButton } from 'react-paystack';
import Swal from 'sweetalert2';
import { saveOrderDataBff } from '../api/PupilApi';

const FoodOrder = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [num, setNum] = useState(1);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const params = useParams();
  const userIndex = `${params.foodId}`;
  const userData = data[userIndex - 1];

  const increase = () => {
    setNum(num + 1);
  };

  const decrease = () => {
    num > 1 ? setNum(num - 1) : setNum(1);
  };

  // Check if access token is available
  const accessToken = localStorage.getItem('accessToken');
  const isLoggedIn = !!accessToken;

  useEffect(() => {
    if (isLoggedIn) {
      const userDetails = JSON.parse(localStorage.getItem('userDetails')); // Example structure
      if (userDetails) {
        setEmail(userDetails.email);
        setName(userDetails.name);
        setPhone(userDetails.phone);
      }
    }
  }, [isLoggedIn]);

  // Payment Method
  const publicKey = process.env.REACT_APP_PAYSTACK_KEY;
  const amount = userData.price * num * 100 * 750;

  const componentProps = {
    email,
    amount,
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: () => {
      Swal.fire('Transaction Successful', '🧶🧶🧶🧶🧶🧶🧶', 'success');
    },
    onClose: () => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    },
  };

  const handleBuyNow = () => {
    if (isLoggedIn) {
      Swal.fire({
        title: 'Confirm Your Order',
        text: `Are you sure you want to pay $${userData.price * num}?`,
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Pay Now',
        denyButtonText: 'Cash on Delivery',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          document.querySelector('.paystack-button').click();
        } else if (result.isDenied) {
          const shippingChargeValue = userData.price * num > 150 ? 0 : 30;
          const orderRequest = {
            totalCost: shippingChargeValue + userData.price * num,
            shippingCharge: userData.price * num > 150 ? "Free" : 30,
            products: [userData],
            shopName: userData.shopName,
            orderStatus: "Pending",
            paymentMethod: "COD",
            shopEmail: userData.shopEmail,
            orderedUserName: email,
            orderedPersonMobile: phone,
            orderedPersonName: name,
          };

          // Call the API to save the order
          saveOrderDataBff(orderRequest)
            .then((response) => {
              Swal.fire({
                title: 'Order Placed',
                text: 'Your order has been placed successfully. Please pay on delivery.',
                icon: 'success',
              });
            })
            .catch((error) => {
              console.error("Error placing order:", error);
              Swal.fire({
                icon: 'error',
                title: 'Order Failed',
                text: 'There was an error placing your order. Please try again.',
              });
            });
        }
      });
    } else {
      Swal.fire({
        icon: 'info',
        title: 'Please Login',
        text: 'You need to log in to proceed with your order.',
        confirmButtonText: 'Login',
      }).then(() => {
        window.location.href = '/signin'; // Redirect to login page
      });
    }
  };

  // Handle Back Button
  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <>
      <Navbar />

      <div className='max-w-[1320px] mx-auto p-3 md:p-10 my-10'>
        <h1 className='mb-5 text-center font-bold text-blue-700 text-4xl'>ORDER NOW</h1>
        <button onClick={handleGoBack} className='mb-4 bg-gray-200 px-4 py-2 rounded-lg'>
          Back
        </button>
        <div className='grid md:grid-cols-2 gap-10'>
          <div>
            <img src={userData.image} className='w-full h-[400px] object-cover shadow-lg' alt={userData.name} />
          </div>
          <div className='text-center px-2'>
            <p className='text-slate-700 text-3xl mb-3'>{userData.name}</p>
            <div className='flex justify-center items-center'>
              <span onClick={decrease} className='cursor-pointer hover:bg-slate-500 p-2'>
                <AiOutlineMinus size={30} />
              </span>
              <span className='mx-4 border-slate-700 border px-5 text-2xl'>{num}</span>
              <span onClick={increase} className='cursor-pointer hover:bg-slate-500 p-2'>
                <AiOutlinePlus size={30} />
              </span>
            </div>
            <div className='mt-4 w-full md:w-[50%] mx-auto bg-slate-400 shadow-lg py-2 text-white text-2xl'>
              Rs.{userData.price * num}
            </div>
            <button onClick={handleBuyNow} className='mt-4 w-full md:w-[50%] mx-auto bg-[#D75127] shadow-lg py-2 text-white border-none rounded-none hover:scale-105 text-2xl'>
              Buy it now
            </button>
            <div className='mt-4 text-orange-600 text-xl'>{userData.description}</div>
          </div>
        </div>
      </div>

      <PaystackButton className="paystack-button hidden" {...componentProps} />

      <Footer />
    </>
  );
};

export default FoodOrder;
